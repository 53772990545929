.contact-section {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 900px;
    margin-bottom: 3em;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.contact-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.contactSection-img {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    position: relative;
    height: 100%;
    max-height: 600px;
}

.contactSection-img img {
    width: 100%;
    height: 100%;
}

.contact-con-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1100px;
    width: 100%;
    transform: translateY(-70%);
    z-index: 2;
    margin: 0 auto;
    text-align: center;
}


.contact-con-img img {
    width: 100%;
    max-width: 1100px;
    max-height: 600px;
    z-index: 3;
}

.contact-details{
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 320px;
    text-align: center;
    
}

/* .contact-details {
    position: absolute;
    left: 0;
    height: 100%;
    width: 300px;
    color: white;
    text-align: center;
    z-index: 3;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
} */

.contact-title {
    font-family: 'purebliss';
    font-size: 4rem;
    font-weight: 100;
    margin: 0;
    padding: 2px;
}

.contact-subtitle {
    font-family: 'pureblissPoppinsLight';
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 100;
    margin: 0;
}

.con-icons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 20px 0;
}

.address-details {
    /* position: absolute; */
    /* left: 15%;
    top: 70%; */
}
.contact-form{
    background-color: rgba(0, 0, 0, 0.5);
    padding: 70px 30px;
}


.reachus-section{
    padding:20px
}
.contact-info {
    display: flex;
    font-family: 'pureblissPoppinsLight';
    font-size: 1rem;
    text-align: start;
    gap: 10px;
    word-wrap: break-word;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}


@media (max-width: 768px) {
    .contact-section {
        overflow: hidden;
        height: 100%;
        max-height: 700px;
        margin-bottom: 3em;
    }

    .contact-con-img {
        max-width: 600px;
        width: 100%;
        transform: translateY(-95%);
        margin: 0 auto;
        text-align: center;
    }


    .contact-con-img img {
        width: 100%;
        max-width: 600px;
        max-height: 600px;
        z-index: 3;
    }

    .contact-details {
        height: 100%;
        width: 100%;
        /* left:30%; */
        /* position: relative; */
    }

    .contact-title {
        font-size: 2rem;
    }

    .address-details {
        left: 10%;
        top: 0%;
        /* position: relative; */
    }

    /* .contact-form {
        position: absolute;
        right: 10%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        border-radius: 10px;
        z-index: 3;
        width: 40%;
        max-width: 600px;
    } */

    .contact-form form {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }
}

@media (max-width: 320px) {
    .contact-section {
        overflow: hidden;
        height: 100%;
        max-height: 700px;
        margin-bottom: 5em;
    }

    .contact-con-img {
        max-width: 600px;
        width: 100%;
        transform: translateY(-5%);
        margin: 0 auto;
        text-align: center;
    }


    .contact-con-img img {
        width: 100%;
        max-width: 600px;
        max-height: 600px;
        height: 100%;
        z-index: 3;
    }

    .contact-details {
        height: auto;
        width: 200px;
        /* height: 100%; */
        
        text-align: center;
        backdrop-filter: blur(3.8px);
        -webkit-backdrop-filter: blur(3.8px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    /* background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 300px;
    text-align: center; */

    .contact-title {
        font-size: 2rem;
    }

    .address-details {
        left: 10%;
        top: 70%;
        /* position: relative; */
    }

    /* .contact-form {
        position: absolute;
        right: 10%;
        left: 1%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        border-radius: 10px;
        z-index: 3;
        width: 85%;
        max-width: 600px;
        transform: translateY(-30%);
    } */

    .contact-form form {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }
}