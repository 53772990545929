.cart-section {
    background-color: #ECE6D8;
    overflow: hidden;
    max-width: 100%;
    /* padding-bottom: 5px; */
    position: relative;
    height:auto;
}

.swiper-container {
    position: relative;
}
.cart {
  /* background-color: #ECE6D8; */
  background-color: hsl(44, 40%, 91%)  ;
  /* ; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: left;
  max-width: 100%;
  max-height: 150px;
  display:flex
}

.cart-image {
  width: 50%;
  height: auto;
  border-radius: 10px;
}



.cartdiv{
  display:flex;
  gap: 10px;
}

.cart-card{
  background-color:  #49444b
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px); */
}

