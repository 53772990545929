.shop-container {
    text-align: center;
    padding: 20px;
}

.cart-title {
    font-size: 2rem;
    margin-bottom: 50px;
    text-align: start;
    color: #6B4028;
    font-family: 'pureblissPoppinsLight';
}

.swiper-container {
    position: relative;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    background-color: none;
    padding-bottom: 40px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures equal spacing */
    height: 100%; /* Ensures cards are the same height */
    /* background-color: #ECE6D8; */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* padding: 10px; */
    text-align: left;
    width: 200px;
    overflow: hidden;
    box-sizing:border-box
}

.card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display:block;
    margin:0;
    padding:0;
    /* border-radius: 8px; */
}

.card-description {
    font-size: 1rem;
    margin: 10px 0;
    padding:  0px 10px 0px 10px;
}

.card-price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    padding:  0px 10px 0px 10px;
}

.card-button {
    background-color: #7B967A;
    /* width: 100%; */
    color: #fff;
    border: none;
    /* padding: 10px 20px; */
    /* border-radius: 4px; */
    cursor: pointer;
    padding: 10px 0px 10px 0px;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    margin:2px
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 15px;
    color:#fff;
}

.swiper-button-next {
    right: -60px;
}

.swiper-button-prev {
    left: -60px;
}

/* Responsive styles */
@media (max-width: 1280px) {
    .shop-title {
        font-size: 1.8rem;
    }

    .cart-title {
        font-size: 1.8rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 35px;
        height: 35px;
    }

    .swiper-button-next {
        right: -50px;
    }

    .swiper-button-prev {
        left: -50px;
    }
}

@media (max-width: 1080px) {
    .shop-title {
        font-size: 1.6rem;
    }

    .cart-title {
        font-size: 1.6rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 30px;
        height: 30px;
    }

    .swiper-button-next {
        right: -40px;
    }

    .swiper-button-prev {
        left: -40px;
    }
}

@media (max-width: 896px) {
    .shop-title {
        font-size: 1.4rem;
    }

    .cart-title {
        font-size: 1.4rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 25px;
        height: 25px;
    }

    .swiper-button-next {
        right: -30px;
    }

    .swiper-button-prev {
        left: -30px;
    }
}

@media (max-width: 640px) {
    .shop-title {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .cart-title {
        font-size: 1.2rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 34px !important;
        height: 34px !important;
    }

    .swiper-button-next {
        right: -20px;
        width: 34px !important;
        height: 34px !important;
    }

    .swiper-button-prev {
        left: -20px;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 10px;
    }
}